<template>
  <div :class="[stopper({ color }), { uppercase: !isLowerText }]">
    <div :class="[classes.inner, innerBg]">
      <div :class="classes.innerWrapper">
        <Icon
          v-if="iconName && iconAlign === STOPPER_ICON_ALIGN.LEFT"
          :name="iconName"
          :class="iconClassName"
          :size="iconHeight"
        />
        {{ text }}
        <Icon
          v-if="iconName && iconAlign === STOPPER_ICON_ALIGN.RIGHT"
          :name="iconName"
          :class="iconClassName"
          :size="iconHeight"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cva, cx, type VariantProps } from 'cva'
import { STOPPER_COLORS, STOPPER_ICON_ALIGN } from '@/constants'

withDefaults(defineProps<Props>(), {
  color: STOPPER_COLORS.RED,
  innerBg: '',
  iconName: '',
  iconClassName: '',
  iconWidth: 0,
  iconHeight: 0,
  iconAlign: STOPPER_ICON_ALIGN.LEFT,
  text: '',
  isLowerText: false,
})

const classes = {
  base: cx([
    'min-w-max',
    'rounded-md',
    'font-bold',
    'text-[10px]/[11px]',
    'text-primary-on-color',
  ]),
  inner: cx([
    'bg-cover',
    'bg-no-repeat',
  ]),
  innerWrapper: cx([
    'flex',
    'items-center',
    'justify-center',
    'py-[4.5px]',
    'px-[8px]',
  ]),
}

const stopper = cva(classes.base, {
  variants: {
    color: {
      [STOPPER_COLORS.BLUE]: 'bg-gradient-stopper-buyers-choice',
      [STOPPER_COLORS.GREEN]: 'bg-gradient-stopper-powerful-battery',
      [STOPPER_COLORS.YELLOW]: 'bg-gradient-stopper-good-camera',
      [STOPPER_COLORS.VIOLET]: 'bg-gradient-stopper-fast-delivery',
      [STOPPER_COLORS.GRAY]: 'bg-gradient-stopper-has-video',
      [STOPPER_COLORS.RED]: 'bg-bg-red-error',
      [STOPPER_COLORS.LIGHT_BLUE]: 'bg-main-blue-1000',
      [STOPPER_COLORS.DEEP_BLUE]: 'bg-bg-blue-accent',
      [STOPPER_COLORS.SALE]: 'bg-gradient-sale',
    },
  },
})

export type StopperProps = VariantProps<typeof stopper>

interface Props {
  color: StopperProps['color']
  innerBg?: string
  iconName?: string
  iconClassName?: string
  iconWidth?: number
  iconHeight?: number
  iconAlign?: string
  text: string
  isLowerText?: boolean
}
</script>