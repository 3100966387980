import routerOptions0 from "/builds/pedant/pedant-market-frontend/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/builds/pedant/pedant-market-frontend/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}