/* eslint-disable @typescript-eslint/no-explicit-any */
import type { $Fetch, FetchOptions, FetchRequest } from 'ofetch'
import qs from 'qs'

interface IFetchFactory {
  method: 'GET' | 'HEAD' | 'PATCH' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'get' | 'head' | 'patch' | 'post' | 'put' | 'delete' | 'connect' | 'options' | 'trace'
  url: string
  fetchOptions?: FetchOptions<'json'>
  body?: object
  headers?: HeadersInit
}

// Solution from: https://github.com/unjs/ofetch/issues/119#issuecomment-1366602174
export const myQuerySerializer = (params: Record<string, any>) => {
  const cleanedParams = Object.keys(params)
    .filter(key => params[key] !== null)
    .reduce((acc, key) => {
      acc[key] = params[key]
      return acc
    }, {} as Record<string, any>)

  // Use your favorite serializer:
  // https://www.npmjs.com/package/qs
  // http://api.jquery.com/jquery.param/
  return `?${qs.stringify(cleanedParams)}`
}

function wrapFetch(fetch: $Fetch) {
  return (request: FetchRequest, options: FetchOptions) => {
    // before
    /* console.log('original request', {
      request, // string
      options, // { params: {...} }
    }) */

    // modify request if has params in options
    let modifiedRequest, modifiedOptions
    if (options && options.params) {
      // append params string and delete params from options
      modifiedRequest = request + myQuerySerializer(options.params)
      modifiedOptions = options.params
    }
    else {
      // use as is
      modifiedRequest = request
      modifiedOptions = options
    }

    // after
    /* console.log('modified request', {
      request: modifiedRequest,
      options: modifiedOptions,
    }) */

    return fetch(modifiedRequest, modifiedOptions)
  }
}

/*
 The FetchFactory acts as a wrapper around an HTTP client.
 It encapsulates the functionality for making API requests asynchronously
 through the call function, utilizing the provided HTTP client.
*/
class FetchFactory {
  private readonly $fetch: $Fetch

  constructor(fetch: $Fetch) {
    this.$fetch = wrapFetch(fetch) as $Fetch
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param data the body data
   * @param fetchOptions fetch options
   * @returns
   */
  async call<T>({ method, url, fetchOptions, body, headers = {
    'Content-Type': 'application/json',
  } }: IFetchFactory): Promise<T> {
    return this.$fetch<T>(
      url,
      {
        method,
        body,
        headers,
        ...fetchOptions,
      },
    )
  }
}

export default FetchFactory