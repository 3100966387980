
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91searchQuery_93WY0mo4gr7vMeta } from "/builds/pedant/pedant-market-frontend/pages/-search/[searchQuery].vue?macro=true";
import { default as _91id_93UyJMbDvmq8Meta } from "/builds/pedant/pedant-market-frontend/pages/[modelGroupSlug]/[modelSlug]/[id].vue?macro=true";
import { default as indexETEb55EhLWMeta } from "/builds/pedant/pedant-market-frontend/pages/[modelGroupSlug]/[modelSlug]/index.vue?macro=true";
import { default as indexWCRCco2xNRMeta } from "/builds/pedant/pedant-market-frontend/pages/[modelGroupSlug]/index.vue?macro=true";
import { default as account2LLAWj4JbQMeta } from "/builds/pedant/pedant-market-frontend/pages/account.vue?macro=true";
import { default as cartwJfyAmxia1Meta } from "/builds/pedant/pedant-market-frontend/pages/cart.vue?macro=true";
import { default as catalog_45deviceSv9mCNZvR6Meta } from "/builds/pedant/pedant-market-frontend/pages/catalog-device.vue?macro=true";
import { default as cataloglw6mkISdCiMeta } from "/builds/pedant/pedant-market-frontend/pages/catalog.vue?macro=true";
import { default as company_45details1HJNuwOpWzMeta } from "/builds/pedant/pedant-market-frontend/pages/company-details.vue?macro=true";
import { default as companyRzHvx0hzPKMeta } from "/builds/pedant/pedant-market-frontend/pages/company.vue?macro=true";
import { default as comparisonZZx6WLhBteMeta } from "/builds/pedant/pedant-market-frontend/pages/comparison.vue?macro=true";
import { default as contactsf5IghjpA1ZMeta } from "/builds/pedant/pedant-market-frontend/pages/contacts.vue?macro=true";
import { default as indexvpUcUFtxsxMeta } from "/builds/pedant/pedant-market-frontend/pages/documents/consent/index.vue?macro=true";
import { default as favouriteMtZgcmnqSjMeta } from "/builds/pedant/pedant-market-frontend/pages/favourite.vue?macro=true";
import { default as garantiaomeYvLKUJ5Meta } from "/builds/pedant/pedant-market-frontend/pages/garantia.vue?macro=true";
import { default as garantijnoe_45obsluzhivaniek0zxDu9zmpMeta } from "/builds/pedant/pedant-market-frontend/pages/garantijnoe-obsluzhivanie.vue?macro=true";
import { default as guaranteeQkm6dNluXNMeta } from "/builds/pedant/pedant-market-frontend/pages/guarantee.vue?macro=true";
import { default as indexelHgapGshAMeta } from "/builds/pedant/pedant-market-frontend/pages/index.vue?macro=true";
import { default as out_45of_45stockFuPvcS8xbfMeta } from "/builds/pedant/pedant-market-frontend/pages/out-of-stock.vue?macro=true";
import { default as partner_45credentials3Jhxb36OJNMeta } from "/builds/pedant/pedant-market-frontend/pages/partner-credentials.vue?macro=true";
import { default as payment9UYut42HYnMeta } from "/builds/pedant/pedant-market-frontend/pages/payment.vue?macro=true";
import { default as podpiskaAbNU7KlaR0Meta } from "/builds/pedant/pedant-market-frontend/pages/podpiska.vue?macro=true";
import { default as redirectKRnS2MMgU8Meta } from "/builds/pedant/pedant-market-frontend/pages/redirect.vue?macro=true";
import { default as returns2mEf9RSVPMeta } from "/builds/pedant/pedant-market-frontend/pages/return.vue?macro=true";
import { default as reviewsKgZOLYDNKJMeta } from "/builds/pedant/pedant-market-frontend/pages/reviews.vue?macro=true";
import { default as _91id_93P2kpdRbNepMeta } from "/builds/pedant/pedant-market-frontend/pages/subscription-order-accepted/[id].vue?macro=true";
import { default as _91id_93KNKPXbBTBdMeta } from "/builds/pedant/pedant-market-frontend/pages/subscription/[id].vue?macro=true";
import { default as index5nsA9O12HZMeta } from "/builds/pedant/pedant-market-frontend/pages/subscription/form/index.vue?macro=true";
import { default as indexyt3wBRGqmyMeta } from "/builds/pedant/pedant-market-frontend/pages/subscription/index.vue?macro=true";
import { default as _91id_93hxc7PWoWQMMeta } from "/builds/pedant/pedant-market-frontend/pages/thanks/[id].vue?macro=true";
import { default as trade_45inyd1hjJBLXgMeta } from "/builds/pedant/pedant-market-frontend/pages/trade-in.vue?macro=true";
export default [
  {
    name: "-search-searchQuery",
    path: "/-search/:searchQuery()",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/-search/[searchQuery].vue")
  },
  {
    name: "modelGroupSlug-modelSlug-id",
    path: "/:modelGroupSlug()/:modelSlug()/:id()",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/[modelGroupSlug]/[modelSlug]/[id].vue")
  },
  {
    name: "modelGroupSlug-modelSlug",
    path: "/:modelGroupSlug()/:modelSlug()",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/[modelGroupSlug]/[modelSlug]/index.vue")
  },
  {
    name: "modelGroupSlug",
    path: "/:modelGroupSlug()",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/[modelGroupSlug]/index.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/account.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/cart.vue")
  },
  {
    name: "catalog-device",
    path: "/catalog-device",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/catalog-device.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/catalog.vue")
  },
  {
    name: "company-details",
    path: "/company-details",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/company-details.vue")
  },
  {
    name: "company",
    path: "/company",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/company.vue")
  },
  {
    name: "comparison",
    path: "/comparison",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/comparison.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/contacts.vue")
  },
  {
    name: "documents-consent",
    path: "/documents/consent",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/documents/consent/index.vue")
  },
  {
    name: "favourite",
    path: "/favourite",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/favourite.vue")
  },
  {
    name: "garantia",
    path: "/garantia",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/garantia.vue")
  },
  {
    name: "garantijnoe-obsluzhivanie",
    path: "/garantijnoe-obsluzhivanie",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/garantijnoe-obsluzhivanie.vue")
  },
  {
    name: "guarantee",
    path: "/guarantee",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/guarantee.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/index.vue")
  },
  {
    name: "out-of-stock",
    path: "/out-of-stock",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/out-of-stock.vue")
  },
  {
    name: "partner-credentials",
    path: "/partner-credentials",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/partner-credentials.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/payment.vue")
  },
  {
    name: "podpiska",
    path: "/podpiska",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/podpiska.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/redirect.vue")
  },
  {
    name: "return",
    path: "/return",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/return.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/reviews.vue")
  },
  {
    name: "subscription-order-accepted-id",
    path: "/subscription-order-accepted/:id()",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/subscription-order-accepted/[id].vue")
  },
  {
    name: "subscription-id",
    path: "/subscription/:id()",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/subscription/[id].vue")
  },
  {
    name: "subscription-form",
    path: "/subscription/form",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/subscription/form/index.vue")
  },
  {
    name: "subscription",
    path: "/subscription",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/subscription/index.vue")
  },
  {
    name: "thanks-id",
    path: "/thanks/:id()",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/thanks/[id].vue")
  },
  {
    name: "trade-in",
    path: "/trade-in",
    component: () => import("/builds/pedant/pedant-market-frontend/pages/trade-in.vue")
  }
]