import type { RouteLocationNormalized } from 'vue-router'
import mixpanel from 'mixpanel-browser'
import { ROUTE_NAMES } from '@/constants'

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    initMixpanel()

    // загружаем seo для всех страниц кроме каталога
    const seoContentStore = useSeoContentStore()
    const isNotCatalogPage = to.name !== ROUTE_NAMES.CATALOG_MODEL && to.name !== ROUTE_NAMES.CATALOG_MODEL_GROUP
    if (isNotCatalogPage) {
      await useAsyncData('fetchSeo', () =>
        seoContentStore.getList(to.path).then(() => true),
      )
    }

    const subscriptionStore = useSubscriptionStore()

    const redirectUrl = await getRedirectUrl(to, {
      device: subscriptionStore.device,
    })
    if (redirectUrl) return navigateTo(redirectUrl.url, { external: redirectUrl.external })

    const catalogStore = useCatalogStore()
    const modelGroupsStore = useModelGroupsStore()
    const utmStore = useUtmStore()

    utmStore.init(parseUtmTags(to.query))

    // загружаем категории моделей
    if (!modelGroupsStore.list?.length)
      await useAsyncData('fetchModelGroups', () =>
        modelGroupsStore.getList().then(() => true),
      )

    // загружаем периоды и ценовые коэффициенты
    if (!subscriptionStore.percents)
      await useAsyncData('fetchPercents', () =>
        subscriptionStore.getPercents().then(() => {
          subscriptionStore.setDefaultPercent()
          return true
        }),
      )

    const isCatalogPage = to.name === ROUTE_NAMES.CATALOG_MODEL || to.name === ROUTE_NAMES.CATALOG_MODEL_GROUP
    if (isCatalogPage && !catalogStore.checkFilter(to)) {
      if (import.meta.client) {
        emitYandexMetrika(YANDEX_METRIKA_GOALS.PAGE_NOT_FOUND, {
          from: to.fullPath,
        })
        mixpanel.track('page_not_found', {
          from: to.fullPath,
        })
      }

      throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
    }
    else {
      if ((to.params.modelGroupSlug || from.params.modelGroupSlug) && to.params.modelGroupSlug !== from.params.modelGroupSlug) {
        const foundModelGroup = modelGroupsStore.list.find(currentModelGroup => currentModelGroup.url === `/${to.params.modelGroupSlug}`)
        if (foundModelGroup) {
          catalogStore.filter.ParentId = foundModelGroup.id
          catalogStore.filter.Models = null
          catalogStore.resetFilters()
        }
      }
    }
  },
)