import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { FetchError } from 'ofetch'
import type {
  Device,
  SubscriptionPercent,
  PassportData,
  SubscriptionOrder,
  Price,
  ServiceCenter,
} from '~/types/models'
import { DEFAULT_ISO_DATE, ROUTE_NAMES } from '@/constants'
import { sendMTSClickId as _sendMTSClickId } from '~/utils'

export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    errors: ref<Record<string, string[]>>({}),
    device: ref<Device | null>(),
    temporaryDeviceId: ref<string>(''), // нужен для getPassportData
    cheaperDevices: ref<Device[] | []>(),
    phone: ref<string | null>(),
    isLoading: ref<boolean>(false),
    temporaryOrderId: ref<string>(''), // нужен для getPassportData
    passportData: ref<PassportData | null>(),
    isMatchesRegistrationAddress: ref<boolean>(false),
    order: ref<SubscriptionOrder | null>(),
    qrCode: ref<string | null>(),
    price: ref<Price | null>(),
    serviceCenter: ref<ServiceCenter | null>(),
    percents: ref<SubscriptionPercent[] | null>(),
    selectedPercent: ref<SubscriptionPercent | null>(),
    formCurrentStep: ref<number>(1),
  }),
  getters: {
    minCreditPrice: state => getMinCreditPrice(state.device),
    monthlyPayment: state =>
      getSubscriptionMonthlyPayment(state.device, state.selectedPercent),
    totalPrice: (state) => {
      if (!state.device || !state.selectedPercent) return

      const basicPrice = state.device.BasicPrice
      const percent = state.selectedPercent.Percent

      if (!basicPrice || !percent) {
        return
      }

      return Math.floor(basicPrice * percent)
    },
    isEmptySubscription: state => state.device === null,
    twelvePercent: state => state.percents?.find(percent => percent.Months === 12),
  },
  actions: {
    addDeviceToSubscription(newDevice: Device) {
      this.device = newDevice
    },
    removeDeviceFromSubscription() {
      this.device = null
    },
    clearSubscription() {
      this.device = null
    },
    getPercents() {
      const { $api } = useNuxtApp()

      return $api.subscriptionPercents
        .getSubscriptionPercent()
        .then((data) => {
          this.percents = data.data
        })
        .catch(() => {
          this.percents = null
        })
    },
    setDefaultPercent() {
      if (!this.percents) return

      this.selectedPercent = this.percents[2]
    },
    async getCheaperDevices() {
      if (!this.device) return

      const { $api } = useNuxtApp()

      return $api.devices
        .getDevices({
          ParentId: this.device.ParentId,
          Models: [this.device.ModelId],
          MaxPrice: this.device.Price,
        })
        .then((data) => {
          this.cheaperDevices = data.data
        })
        .catch(() => {
          this.cheaperDevices = []
        })
    },
    createOrder() {
      const citiesStore = useCitiesStore()

      if (
        !this.device
        || !citiesStore.current
        || !this.selectedPercent
        || !this.phone
        || !this.totalPrice
      )
        return

      const { $api } = useNuxtApp()
      const utmStore = useUtmStore()

      this.isLoading = true

      const payload = {
        FromSite: 'PedantMarket',
        ModelPriceId: this.device.ModelPriceId,
        PurchasingActId: this.device.Id,
        CustomerPhone: this.phone,
        UtmTags: utmStore.utmTagsAsString,
        CityId: citiesStore.current.id,
        SubscriptionBasicPriceId: this.device.SubscriptionBasicPriceId,
        SubscriptionMonths: this.selectedPercent.Months,
        SubscriptionPercent: this.selectedPercent.Percent,
        SubscriptionPayment: this.totalPrice,
        BasicPrice: this.device.BasicPrice,
        MixpanelId: getMixpanelId(),
      }

      return $api.subscriptionOrders
        .createSubscriptionOrder(payload)
        .then((data) => {
          this.order = data
        })
        .catch((error: FetchError) => {
          this.order = null

          this.errors = error.data?.errors as Record<string, string[]> ?? {}
          throw error
        })
        .finally(() => (this.isLoading = false))
    },
    updateOrder() {
      if (
        !this.device
        || !this.selectedPercent
        || !this.phone
        || !this.totalPrice
        || !this.order
      )
        return

      this.isLoading = true

      const payload = {
        CustomerPhone: this.phone,
        ModelPriceId: this.device.ModelPriceId,
        PurchasingActId: this.device.Id,
        SubscriptionBasicPriceId: this.device.SubscriptionBasicPriceId,
        BasicPrice: this.device.BasicPrice,
        SubscriptionMonths: this.selectedPercent.Months,
        SubscriptionPercent: this.selectedPercent.Percent,
        SubscriptionPayment: this.totalPrice,
        MixpanelId: getMixpanelId(),
      } as {
        CustomerPhone: string
        CustomerName?: string
        ModelPriceId: string
        PurchasingActId: string
        SubscriptionBasicPriceId: string
        BasicPrice: number
        SubscriptionMonths: number
        SubscriptionPercent: number
        SubscriptionPayment: number
        MixpanelId: string
      }

      if (this.passportData?.FullName)
        payload.CustomerName = this.passportData.FullName

      const { $api } = useNuxtApp()

      return $api.subscriptionOrders
        .updateSubscriptionOrder(this.order.Id, payload)
        .finally(() => (this.isLoading = false))
    },
    getPassportData(subscriptionOrderId: string) {
      this.isLoading = true

      const { $api } = useNuxtApp()

      return $api.customerPassports
        .getCustomerPassport(subscriptionOrderId)
        .then((data) => {
          const handleInputPassportData = () => {
            const prepareDate = (isoDate: string) => {
              if (isoDate === DEFAULT_ISO_DATE) return ''

              const date = new Date(Date.parse(isoDate))

              const day = date.getDate()
              const month = date.getMonth()

              return [
                `${day < 10 ? '0' : ''}${day}`,
                `${month < 10 ? '0' : ''}${month}`,
                date.getFullYear(),
              ].join('.')
            }

            const {
              birthDate,
              dateOfIssue,
              divisionCode,
              issuedBy,
              surname,
              name,
              patronymic,
              seriesAndNumber,
              placeOfBirth,
              registrationAddressApartment,
              registrationAddressCity,
              registrationAddressHouse,
              registrationAddressRegion,
              registrationAddressStreet,
              residentialAddressApartment,
              residentialAddressCity,
              residentialAddressHouse,
              residentialAddressRegion,
              residentialAddressStreet,
            } = data.passportData

            this.passportData = {
              FullName: [surname, name, patronymic].join(' ').trim(),
              BirthDate: prepareDate(birthDate),
              SeriesAndNumber: seriesAndNumber,
              DateOfIssue: prepareDate(dateOfIssue),
              IssuedBy: issuedBy,
              DivisionCode: divisionCode,
              PlaceOfBirth: placeOfBirth,
              RegistrationAddressRegion: registrationAddressRegion,
              RegistrationAddressCity: registrationAddressCity,
              RegistrationAddressStreet: registrationAddressStreet,
              RegistrationAddressHouse: registrationAddressHouse,
              RegistrationAddressApartment: registrationAddressApartment,
              ResidentialAddressCity: residentialAddressCity,
              ResidentialAddressRegion: residentialAddressRegion,
              ResidentialAddressStreet: residentialAddressStreet,
              ResidentialAddressHouse: residentialAddressHouse,
              ResidentialAddressApartment: residentialAddressApartment,
            } as PassportData
          }

          this.phone = data.customerPhone
          this.temporaryDeviceId = data.deviceId
          this.temporaryOrderId = data.subscriptionOrderId
          handleInputPassportData()
        })
        .catch((error: FetchError) => {
          this.errors = error.data?.errors as Record<string, string[]> ?? {}
          throw error
        })
        .finally(() => (this.isLoading = false))
    },
    savePassportData() {
      if (!this.passportData || !this.order) return

      this.isLoading = true

      const payload = {
        SubscriptionOrderId: this.order.Id,
        Signed: false, // заказ пока не подтвержден по смс
        PassportData: this.passportData,
      }

      const { $api } = useNuxtApp()

      return $api.customerPassports
        .saveCustomerPassport(payload)
        .catch((error: FetchError) => {
          this.errors = error.data?.errors as Record<string, string[]> ?? {}
          throw error
        })
        .finally(() => (this.isLoading = false))
    },
    verifyPassportData(code: string) {
      if (!this.passportData || !this.order) return

      this.isLoading = true

      const payload = {
        SubscriptionOrderId: this.order.Id,
        VerificationCode: code,
      }

      const { $api } = useNuxtApp()

      return $api.customerPassports
        .verifyCustomerPassport(payload)
        .catch((error: Error) => {
          throw error
        })
        .finally(() => (this.isLoading = false))
    },
    getDevice(deviceId?: string) {
      const { $api } = useNuxtApp()
      const router = useRouter()

      return $api.devices
        .getDevice(deviceId ?? this.temporaryDeviceId)
        .then((data) => {
          this.device = data
        })
        .catch(() => {
          this.device = null
          // redirect if device out of stock
          router.push({ name: ROUTE_NAMES.SUBSCRIPTION_OUT_OF_STOCK })
        })
    },
    async getOrder(orderId?: string) {
      const { $api } = useNuxtApp()
      const router = useRouter()

      return $api.subscriptionOrders
        .getSubscriptionOrder(orderId ?? this.temporaryOrderId)
        .then((data) => {
          this.order = data.data as SubscriptionOrder
          this.qrCode = data.additional.QrCode
          this.serviceCenter = data.relations.service_center
          this.price = data.relations.price
        })
        .catch(async () => {
          this.order = null
          this.qrCode = null
          this.serviceCenter = null
          this.price = null

          await router.push({ name: ROUTE_NAMES.NOT_FOUND })
        })
    },
    changeFormCurrentStep(stepNumber: number) {
      this.formCurrentStep = stepNumber
    },
    async sendMTSClickId() {
      if (!this.order) return

      await _sendMTSClickId(this.order.Id, this.order.SubscriptionPayment)
    },
  },
  persist: {
    pick: ['device', 'order', 'phone'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSubscriptionStore, import.meta.hot))
}