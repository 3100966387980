<template>
  <div
    v-if="isShowPossible"
    :class="[
      classes.wrapper,
      horizontalDirection ? `${classes.wrapperColumn} scrollbar-fix` : '',
    ]"
  >
    <template
      v-for="(badgeItem, index) in badgeItems"
      :key="index"
    >
      <BaseStopper
        v-if="badgeItem.isShow"
        :color="badgeItem.color"
        :inner-bg="badgeItem.innerBg ?? undefined"
        :icon-name="badgeItem.iconName ? `stoppers:${badgeItem.iconName}` : undefined"
        :icon-width="badgeItem.iconWidth ? badgeItem.iconWidth : undefined"
        :icon-height="badgeItem.iconHeight ? badgeItem.iconHeight : undefined"
        :icon-class-name="badgeItem.iconName ? (badgeItem.iconAlign === STOPPER_ICON_ALIGN.RIGHT ? 'ml-2' : 'mr-2') : undefined"
        :icon-align="badgeItem.iconAlign"
        :text="badgeItem.text"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue'
import { cx } from 'cva'
import type { StopperProps } from '../base/Stopper.vue'
import type { Device } from '~/types/models'
import { ROUTE_NAMES, STOPPER_COLORS, STOPPER_ICON_ALIGN } from '@/constants'
import { declension } from '@/utils'

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default() { return {} },
    required: true,
  },
  horizontalDirection: {
    type: Boolean,
    default: false,
  },
  isShowSaleBuyoutBadge: {
    type: Boolean,
    default: false,
  },
})

const classes = {
  wrapper: cx([
    'grid',
    'gap-1',
    'grid-flow-row',
    'auto-rows-fr',
    'justify-start',
    'justify-items-start',
    'pointer-events-none',
  ]),
  wrapperColumn: cx([
    'w-full',
    'grid-flow-column',
    'overflow-x-scroll',
  ]),
}

interface BadgeItem {
  text: string
  color: StopperProps['color']
  innerBg?: string
  iconName?: string
  iconWidth?: number
  iconHeight?: number
  iconAlign?: string
  isShow: boolean
}

const badgeItems = computed<Array<BadgeItem>>(() => {
  const { BuyersChoice, PowerfulBattery, GoodCamera, LimitedStock, IsNewModel } = props.device.Badges
  const { DeviceCount, Videos, IsSaleBuyout } = props.device

  const route = useRoute()
  const isCatalogDevicePage = route.name === ROUTE_NAMES.CATALOG_DEVICE

  const limitedStockText = `Остал${DeviceCount === 1 ? 'ась' : 'ось'} ${DeviceCount} ${declension(DeviceCount, ['штука', 'штуки', 'штук'])}`

  const badgeItems: Array<BadgeItem> = [
    {
      text: 'Распродажа',
      color: STOPPER_COLORS.SALE as StopperProps['color'],
      innerBg: 'bg-[url("/static/images/stoppers/sale-bg.png")]',
      iconName: 'flame',
      iconWidth: 12,
      iconHeight: 12,
      iconAlign: STOPPER_ICON_ALIGN.RIGHT,
      isShow: props.isShowSaleBuyoutBadge && IsSaleBuyout,
    },
    {
      text: 'Внутри видео',
      color: STOPPER_COLORS.GRAY as StopperProps['color'],
      iconName: 'play',
      iconWidth: 12,
      iconHeight: 12,
      isShow: !isCatalogDevicePage && !!Videos?.length,
    },
    {
      text: limitedStockText,
      color: STOPPER_COLORS.RED as StopperProps['color'],
      isShow: LimitedStock,
    },
    {
      text: 'Новинка',
      color: STOPPER_COLORS.LIGHT_BLUE as StopperProps['color'],
      isShow: IsNewModel,
    },
    {
      text: 'Хит подписки',
      color: STOPPER_COLORS.BLUE as StopperProps['color'],
      iconName: 'trophy',
      iconWidth: 12,
      iconHeight: 12,
      isShow: BuyersChoice,
    },
    {
      text: 'Мощная батарея',
      color: STOPPER_COLORS.GREEN as StopperProps['color'],
      iconName: 'thunderbolt',
      iconWidth: 12,
      iconHeight: 12,
      isShow: PowerfulBattery,
    },
    {
      text: 'Хорошая камера',
      color: STOPPER_COLORS.YELLOW as StopperProps['color'],
      iconName: 'camera',
      iconWidth: 12,
      iconHeight: 12,
      isShow: GoodCamera,
    },
    {
      text: 'Быстрая доставка',
      color: STOPPER_COLORS.VIOLET as StopperProps['color'],
      iconName: 'hourglass',
      iconWidth: 12,
      iconHeight: 12,
      isShow: false,
    },
  ]

  const firstThreeBadgeItems = badgeItems
    .filter(badgeItem => badgeItem.isShow)
    .slice(0, 3)

  return firstThreeBadgeItems
})

const isShowPossible = computed(() => badgeItems.value.filter(badgeItem => badgeItem.isShow).length)
</script>